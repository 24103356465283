<template>
    <div class="flex flex-col w-0 flex-1" :class="isOpen ? '' : ''">
        <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        <div class="md:hidden">
            <div class="fixed inset-0 flex z-40 transition ease-in-out duration-300 transform" :class="isOpen ? 'opacity-100' : 'hidden'">
            <!--
                Off-canvas menu overlay, show/hide based on off-canvas menu state.

                Entering: "transition-opacity ease-linear duration-300"
                From: "opacity-0"
                To: "opacity-100"
                Leaving: "transition-opacity ease-linear duration-300"
                From: "opacity-100"
                To: "opacity-0"
            -->
            <div class="fixed inset-0 transition ease-in-out duration-300 transform " :class="isOpen ? '-translate-x-0' : '-translate-x-full'">
                <div class="absolute inset-0 bg-grblack opacity-60"></div>
            </div>
            <!--
                Off-canvas menu, show/hide based on off-canvas menu state.

                Entering: "transition ease-in-out duration-300 transform"
                From: "-translate-x-full"
                To: "translate-x-0"
                Leaving: "transition ease-in-out duration-300 transform"
                From: "translate-x-0"
                To: "-translate-x-full"
            -->
            <div class="relative flex-1 flex flex-col max-w-xs w-full bg-grblack">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"  @click="isOpen = !isOpen">
                    <span class="sr-only">Close sidebar</span>
                    <!-- Heroicon name: outline/x -->
                    <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                </div>
                <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <nav class="mt-5 px-2 space-y-1">
                    <menu-items v-on:menuItemClicked="isOpen = false" /> 
                    
                </nav>
                </div>
            </div>
            <div class="flex-shrink-0 w-14">
                <!-- Force sidebar to shrink to fit close icon -->
            </div>
            </div>
        </div>



        <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
            <button
                class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
                aria-label="Open sidebar"
                @click="isOpen = true"
            >
                <!-- Heroicon name: menu -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>
        </div>

        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none" tabindex="0">
            <div class="w-full float-right py-8 pl-0 md:pl-8 lg:pl-16">
                <slot />
            </div>
        </main>
    </div>
</template>

<script>
import MenuItems from "@/components/MenuItems.vue";

export default {
    components: {
        MenuItems
    },
    data() {        
        return {
            isOpen: false
        }
    }
}
</script>

<style scoped>
    .bg-image {
        background-image: url("/ez2view-background.png");
        margin: 0;
        padding: 0;
    }
</style>