<template>
<!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
<!-- This component requires Tailwind CSS >= 1.5.1 and @tailwindcss/ui >= 0.4.0 -->
<div class="relative py-16 bg-white overflow-hidden">
  <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
  </div>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <div class="text-lg mx-auto mb-6">
      <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Getting Started</h1>
      <p class="text-xl text-gray-500 leading-8 font-semibold">NEMreview is a widely used software tool for slicing and dicing a core set of historical data relating to Australia’s energy market. The software was first released in 2001 and has continued to evolve over the past 20 years. </p>
    </div>
    <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
      <p>It's important to note that it is <u>currently in-between versions</u>. NEMreview v6 is installable desktop software that is currently the official release. However, <b>this help site has been designed to assist users of NEMreview v7</b>, a browser-based app which is <b>currently in <i>beta development</i></b> that will eventually replace v6. You can click on the button below to find out more information about logging in to v7. </p>
      <br/>
    </div>
    <ul class="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">

      <router-link to="/getting-started/loggingin" class="block">
      <li class="col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-8 font-bold truncate hover-text-grblue lg:text-lg">Logging in</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Registering and signing in to the online application</p>
          </div>
        </div>
      </li>
      </router-link>

    </ul>

  </div>
</div>
</template>

<script>
export default {
    title: 'Getting Started'
};
</script>