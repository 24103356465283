<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
        <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Timeline of Development</h1>
        <p class="text-xl text-gray-500 leading-8 font-semibold">Version 7 of the software is currently in beta development and we envision that it may be for some time as we continue to evolve the concepts and designs we have to improve the product. If you have any suggestions while we work through this process, we encourage you to <router-link to="/contact-us" class="inline text-grblue hover:underline">contact us</router-link>. 
            <br/>
            <br/>
            We periodically publish release notes, examples of use and other announcements about NEMreview. These updates are available on a separate blog, which you can access following the link below: 
        </p>
        <br/>
        <a href="https://timeline.nemreview.info" class="text-blue-600 hover:underline"><h2 class="text-3xl text-center font-extrabold sm:text-3xl">timeline.nemreview.info</h2></a>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h3 class="mt-16 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Our Agile approach to developing NEMreview</h3>
            <p>As with all of our other software products, we follow an <b><i>intensive, iterative </i> development process</b> .  Our <a href="http://home.global-roam.com/about/the-agile-way-in-which-we-work/" class="text-blue-600 hover:underline">Agile Development Approach is detailed here</a>. While the specifics of our method have varied over time, the underlying purpose has always been the same. </p>
            <br/>
            <p>Building with our Agile approach means that:</p>
            <ul class="pl-12 pt-2 pb-2 list-disc">
                <li>You get access to enhancements to the software <i>sooner</i>;</li>
                <li>You can <i>actively participate</i> in the Agile development process, if you choose to do so;</li>
                <li>You gain the benefit of <i>all of</i> the suggestions we implement, including those suggested by the growing number of other clients who are using the software.</li>
            </ul>
        </div>
    </div>
    </div>
</template>


<script>
export default {
    title: 'Timeline of Development'
};
</script>