import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { RouteConfigSingleView } from 'vue-router/types/router';
import siteMap from '@/router/siteMap';

Vue.use(VueRouter);

const flatten = (items: Array<RouteConfigSingleView>, prefix: string): RouteConfig[] => {
  return items.reduce((acc: RouteConfigSingleView[], item: RouteConfigSingleView) => {
    const route = {
      name: item.name,
      path: `${prefix ? '/' + prefix : ''}/${item.path}`,
      component: item.component
    } as RouteConfigSingleView;
    acc.push(route);

    if (item.children && item.children.length) {
      acc = acc.concat(flatten(item.children, item.path));
    }

    return acc;
  }, []);
};

export const routes: Array<RouteConfig> = [
  { path: '/download/nemreview-installer', redirect: '/getting-started/loggingin' },
  ...flatten(siteMap, '')
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }  
});

export default router;
