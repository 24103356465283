<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/guide" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Guide</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/guide/format" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    <p>Step 5: <b> Formatting Your Chart</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-semibold tracking-tight text-grblack sm:text-5xl sm:leading-10">Step 6: <span class="font-bold ">Output Your Trend</span></h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">Once you are finished creating and configuring your trend, you can read the instructions below that explain the different ways in which you can output your trend.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Create a Copy/Template</h3>
            <p>Each trend is unique to a single user. It however possible to share a 'template' of your trend.</p>
            <br/>
            <p>If you wish to share the trend you have created with others within your organisation, or perhaps other NEMreview users outside your organisation, you can do so by creating a template of your trend. To do this click on the pages icon near the top of your screen, like shown in the screenshot below. </p>
            <br/>
            <br/>
            <img class="mx-auto w-full lg:w-1/3" src="@/assets/outputdata-image4.png" alt="Choosing your ouput">
            <br/>
            <p>You will then be able to copy the URL of your template to clipboard (like shown below), and then share the link with other NEMreview users. This also works if you want to create a duplicate of your trend and then modify it in some way. Just remember to rename the duplicate copy when you are finished.</p>
            <br/>
            <img class="mx-auto w-full lg:w-2/5" src="@/assets/outputdata-image5.png" alt="Choosing your ouput">
            <br/>
            <p>Following these instructions and using the URL in the green box is the only way to share a template. You <b>cannot</b> simply copy the URL in the address bar of your browser, and then share it with others - as those other users will get a persmission error when trying to open the link. </p>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Save the Trend</h3>
            <p>The star button near the top of your screen allows you to save the trend so that you can access and edit it again in the future. Once saved, it will then appear in the existing trends list when you log-in as shown in Step 1.</p>
            <br/>
            <img class="mx-auto w-full lg:w-1/5" src="@/assets/outputdata-image6.png" alt="Save Trend Button">
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Output a Chart</h3>
            <p>You can output a chart directly as an image file. If you followed the instructions in Step 5 that specify how to format a chart, you will see a blue button that says 'Save as image' towards the bottom-left of your screen. Click on this button and you will be able to save the chart as a .png file to your local machine.</p>
            <br/>
            <img class="mx-auto w-full lg:w-1/3" src="@/assets/outputdata-image1.png" alt="Choosing your ouput">
            <br/>
            <p></p>


            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Output a Table</h3>
            <p>If you selected 'Table' in Step 4 you will be taken to a screen that shows your trend in tabular form. 
            <br/>
            <p>If you then wish to download that table as a CSV, you can click on the blue button in the top right-hand corner of your table like shown below.</p>
            <br/>
            <img class="mx-auto w-full lg:w-1/3" src="@/assets/outputdata-image2.png" alt="Choosing your ouput">
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Output a CSV File</h3>
            <p>If you selected 'File' in Step 4 you will be taken to a tab that will allow you to download the CSV directly as shown below. </p>
            <br/>
            <img class="mx-auto w-full lg:w-1/2" src="@/assets/outputdata-image3.png" alt="Choosing your ouput">
            <br/>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Output Your Trend'
};
</script>