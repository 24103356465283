<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/guide" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Guide</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/guide/chooseoutput" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    <p>Step 4: <b> Choose Output</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/guide/outputtrend" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <p>Step 6: <b> Output Your Trend</b></p>
                    <!-- Heroicon name: arrow-narrow-right -->
                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </router-link>
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-semibold tracking-tight text-grblack sm:text-5xl sm:leading-10">Step 5: <span class="font-bold ">Formatting Your Chart</span></h1>
            <br/>
            <p class="text-xl text-gray-500 leading-8 font-semibold">This step only applies if you chose 'Chart' as your output method in the last step. If you have chosen to output your data as a table or CSV file, then please skip to the next step.  </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Formatting the Series</h3>
            <p>In the left-side pane you can format each series in your trend by clicking on the pencil icon. Here in this pane you will be able to change the series type (Line, Column or Area), and then format the series further through colour, line thickness, etc. </p>
            <br/>
            <img class="mx-auto w-full lg:w-2/5" src="@/assets/format-image1.png" alt="Choosing your ouput">
            <br/>
            <p>If you would like to have two y-axis on your chart, you can specify which axis the series will be plotted on using the "Change Axis" field. </p>
            <br/>
            <p>Clicking on the colour picker next to "Series Colour", allows you to specify the specific colour and to define the transparency of the series as it will display on the chart. This is useful if you want to layer different series of the same type on top of each other.</p>
            <br/>
            <img class="mx-auto w-full lg:w-1/4" src="@/assets/format-image2.png" alt="Choosing your ouput">
            <br/>
            <p>For each of the edit screens at the stage, you will need to click away from the selection pane in order for your settings to save. </p>
            <p><b>Please note:</b></p>
            <ul class="pl-16 pt-2 pb-2 list-disc">
                <li>The points displayed on your chart <b>will align to the end of the period for each data point</b> within your series as you configured it.</li>
                <li>If you want to configure similar shades to series, it might be useful to copy the hex code of the colour and then adjusting the colour as necessary.</li>
            </ul>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Formatting the Axes</h3>
            <p>To format the axis themselves, click on the pencil icon like shown in our screenshot below.</p>
            <br/>
            <img class="mx-auto w-full lg:w-1/4" src="@/assets/format-image3.png" alt="Choosing your ouput">
            <br/>
            <p>This opens up a pane where you can then change the axis title, scale, min, max, etc. </p>
            <br/>
            <img class="mx-auto w-full lg:w-1/2" src="@/assets/format-image5.png" alt="Choosing your ouput">
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Formatting the Chart</h3>
            <p>The left-sided pane will also give you several toggles for adjusting how and if you want other features on your chart to be displayed e.g. showing title, legend, scrollbar, etc. Included is an option for displaying tooltips, which may be useful in making the charts more readable. </p>
            <br/>
            <img class="mx-auto w-full lg:w-1/4" src="@/assets/format-image4.png" alt="Choosing your ouput">
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Zooming in on the Chart</h3>
            <p>You can zoom in on the chart using the bar at the top of your chart by simply dragging the sliders to the section of the timeline that you want to zoom in on.</p>
            <br/>
            <img class="mx-auto w-full" src="@/assets/format-image6.png" alt="Zoom Bar">
            <br/>
            <p>Alternatively, you can also click and drag on the chart directly to zoom in on that section of the chart.</p>
            <br/>
            <p>Click on the 'Show All' button in the top right of you chart to zoom back out.</p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Formatting Your Chart'
};
</script>