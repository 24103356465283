<template>
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-48 lg:w-80">
      <div class="flex flex-col h-0 flex-1">
        <div class="flex-1 flex flex-col pb-4 bg-grblack-300">
          <nav class="mt-8 flex-1 px-2">
            <div class="pb-8">
              <a href="https://app.nemreview.info" target="_blank">
              <button type="button" class="mx-auto h-9 w-full items-center px-1 py-1 border-2 border text-sm leading-4 font-medium rounded shadow-sm text-grblack bg-gryellow-800 font-bold hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                  <div class="w-full inline-flex">
                    <span class="my-auto pr-2 flex-1 text-md">
                    Launch App
                    </span>
                    <span class="flex-initial">
                        <svg class="mx-auto my-auto" fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="22px" height="22px"><path fill="none" stroke="#403b41" stroke-miterlimit="10" stroke-width="2" d="M20 12L20 20 4 20 4 4 12 4M20 10L20 4 14 4M20 4L9 15"/></svg>
                    </span>
                  </div>
              </button>
              </a>
            </div>
            <menu-items />
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItems from "@/components/MenuItems.vue";

export default {
  components: {
    MenuItems
  }  
}
</script>

<style scoped>

.bg-grblue {
  background-color: rgba(82 , 115, 170, 1);
}

.bg-gryellow-800 {
    background-color: rgba(221 , 180, 0, 0.8);
}

.text-grblack {
  color: #403b41;
}

.hover-text-grred:hover {
  color: #E53E45;
}

.border-grblue {
  --tw-border-opacity: 1;
  border-color: rgba(82, 115, 170, var(--tw-border-opacity));
}

.bg-grgrey {
  background-color: #f0f0f0;
}
</style>