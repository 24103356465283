import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import titleMixin from '@/mixins/titleMixin';
import '@/assets/tailwind.css';

Vue.mixin(titleMixin);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  created() {
    // see: https://stackoverflow.com/questions/47677220/vuejs-history-mode-with-github-gitlab-pages
    // the other part to this hack is found in the 404.html file
    if (sessionStorage.redirect) {
      const redirect = sessionStorage.redirect;
      delete sessionStorage.redirect;
      this.$router.push(redirect);
    }
  }
}).$mount('#app');
