<template>
    <div class="relative py-16 bg-grblack overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
        <h1 class="mt-2 mb-10 text-5xl text-center leading-8 font-extrabold tracking-tight text-white sm:text-5xl sm:leading-10">Contact Us</h1>
        <p class="text-xl text-white leading-8 font-semibold">Please use the contact information below to get in touch with us if you are having any issues with NEMreview or if you have any feedback or suggestions for the app.  </p>
        </div>
        <div class="prose-lg text-white mx-auto">
        <p>You can call our Brisbane office during business hours on <b>+61 7 3368 4064</b> to speak with us. Alternatively, you can use the contact form below to lodge a support case with our software team and we will get in touch with you shortly. </p>
        <br/>
        <iframe src = "https://feedback.global-roam.com/#/nemreview-support" title = "Feedback form" height=500 width= 100% scrolling="no">
        </iframe>
        </div>
    </div>
    </div>
</template>

<style scoped>
.bg-grblack {
    background-color: #403b41;
}
</style>

<script>
export default {
    title: 'Contact Us'
};
</script>
