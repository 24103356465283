<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/getting-started" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Getting Started</p></router-link>
        </div>
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Logging In</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">If you are new to NEMreview v7 and are yet to register an account, you will need to do so before you can begin using the app. Once you have an account, you will be able to log-in and start creating your own trends. </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>NEMreview v7 is completely online, so you can access it from any standard web browser. However, we recommend using Chome or Safari as we have more intensively focused our development efforts on those two browsers. You can access the app at the following link:</p>
            <br/>
            <a href="https://app.nemreview.info" class="text-blue-600 hover:underline"><h2 class="text-3xl text-center font-extrabold sm:text-3xl">app.nemreview.info</h2></a>
            <br/>
            <p>If you are not already logged in, you will see a screen like shown in the screenshot below, prompting you to log-in or register a Global-Roam Account. If you need to register a new account, click on the green button and then follow the instructions. </p>
            <br/>
            <div class="w-1/3 mx-auto screenshot">
                <img class="mx-auto" src="@/assets/loggingin-image2.png" alt="Logging In">
                <img class="watermark" src="@/assets/loggingin-image3.png" alt="Logging In - Screenshot Only">
            </div>
            <p>Each licence to NEMreview applies organisation-wide. Once your organisation's licence has been confirmed, each individual user will need to register a Global-Roam account to use NEMreview. Please note that your individual Global-Roam account <b><i>is different</i></b> from the old ‘gr-ID account’ used for NEMreview v6.</p>
            <br/>
            <p>This account will then allow you to log-in to NEMreview v7 and other Global-Roam online apps that your organisation has a licence for. When registering your account, you must provide the email address at the domain of the organisation that owns the licence. For example, if ez2view is licenced to your organisation 'Company XYZ', then you must use your @companyxyz.com email address. </p>
                        
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Logging In'
};
</script>

<style scoped>
    .screenshot {
        width: 450px;
        height: auto;
        position: relative;
        margin: auto;
    }
    .screenshot .watermark {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
    }
    .screenshot:hover .watermark {
        display: inline;
    }
</style>