<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg mb-2 mx-auto">
          <router-link to="/guide" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Guide</p></router-link>
      </div>
      <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
          <div class="-mt-px w-0 flex-1 flex">
              <router-link to="/guide/managetrends" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
              <!-- Heroicon name: arrow-narrow-left -->
              <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg>
              <p>Step 1: <b>Manage Your Trends</b></p>
              </router-link>
          </div>
          <div class="hidden md:-mt-px md:flex">
          </div>
          <div class="-mt-px w-0 flex-1 flex justify-end">
              <router-link to="/guide/transformdata" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
              <p>Step 3: <b> Transform the Data</b></p>
              <!-- Heroicon name: arrow-narrow-right -->
              <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
              </router-link>
          </div>
      </nav> 
      <div class="text-lg mx-auto mb-6">
          <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-semibold tracking-tight text-grblack sm:text-5xl sm:leading-10">Step 2: <span class="font-bold ">Select Your Data Series</span></h1>
          <p class="text-xl text-gray-500 leading-8 font-semibold">In this step, you will choose the data series you would like to input into your trend. The data series you choose here can then be displayed as is (as raw data), or can be included within a formula for your own calculated series as we will explain in the next step. </p>
      </div>
      <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
          <br/>
          <div class="p-4 mx-auto w-5/6 rounded-lg bg-gray-100">
            <h3 class="mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">What Data is Available?</h3>
            <p>NEMreview v7 gives you access to thousands of historical data series related to the Australian energy market. The instructions below show you how to browse through all available data. For the purposes of explanation, the following broad categories of data are available:</p>
            <ul class="pl-16 pt-2 pb-2 list-disc">
                <li><b>AEMO NEM</b>  including: </li>
                    <ul class="pl-12 pt-2 pb-2 list-circle">
                        <li> <b>Regional-level data</b> e.g. regional spot price, total generation, total availability, total demand, solar UIGF, wind UIGF, etc.</li>
                        <li> <b>Unit-level data</b> e.g. unit output, unit availability, dispatch target, semi-dispatch cap, etc.</li>
                        <li> <b>Interconnector data</b> e.g. import/export limits, flow, target flow, settlement residue, etc.</li>
                    </ul>
                <li><b>AEMO WEM</b> e.g. WEM spot price, total generation, unit-specific output, etc. </li>
                <li><b>BOM</b> e.g. air temperature, rain fall, wind speed, etc.</li>
                <li><b>ASX</b> e.g. futures contract price, futures contract volume, etc.</li>
                <li><b>LGC</b> e.g. LGC certificates, LGC spot price </li>
            </ul>
            <br/>
            <p>We've begun to compile a <router-link to="/glossary" class="inline text-grblue hover:underline">Glossary</router-link> that provides descrpitions for data series that we are commonly asked about. </p>
          </div>
          <br/>
          <br/>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Give Your Trend a Title</h3>
          <p>Before we begin selecting data, you should start by giving your trend a unique title. This is a necessary step if you want to recall your trend later. You can do this in the top left-hand corner of your screen like shown below. You can change the name of your trend at any point if you need.</p>
          <br/>
          <img class="mx-auto w-full lg:w-1/4" src="@/assets/selectdataseries-image1.png" alt="Trend Name Field">
          <br/>
          <p>If you want to follow along with our example, we will start by giving our trend the name: <b>"Monthly Wind Stats (Min, Average, Max - and Percentages) since Jan 2010 NEM-wide"</b>.</p>
          <br/>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Select the Date/Time Range</h3>
          <p>At this stage you should also specify the time period over which you wish to plot the data. You can do this by clicking on the button as shown below. </p>
          <br/>
          <img class="mx-auto w-full lg:w-2/5" src="@/assets/selectdataseries-image2.png" alt="Trend Date Selector">
          <br/>
          <p>Clicking on this button will open a pane where you can specify the date and time that you want to conduct your analysis over. Here you will have the option to either set the date/time period as: </p>
          <ul class="pl-16 pt-2 pb-2 list-disc">
              <li><b>Fixed</b>: You specify two static dates/times and the trend will always display the data between these two each time you open the trend. You may find this more useful for one-off analysis. </li>
              <li><b>Relative</b>: You specify a dynamic lookback period and the trend will update the data displayed for this each time you open the trend. You may find this more useful for repeated analysis of the same data series. </li>
          </ul>
          <br/>
          <p>The amount of historical data available depends on how long we have been collecting the data for each specific data set, with some of the core AEMO data sets having data accessible since the start of the NEM in 1998. </p>
          <br/>
          <p>In our example, we want to show NEM-wide wind stats from 2010 up until the end of 2021 so we will set the From Date as "01/01/2010" and the To Date as "01/01/2022". </p>
          <br/>
          <p><b>Please note:</b></p>
          <ul class="pl-16 pt-2 pb-2 list-disc">
              <li>When selecting a fixed date range, the NEM operates on AEST (UTC +10), i.e. without daylight savings, and hence all data will correspond to this time zone.</li>
              <li>NEMreview displays only historical data. If the date you specify in the 'To' field is in the future, the trend will show data up to the present point in time each time it is opened, until the date specified passes. You will need to open the trend each time for it to refresh with up-to date data. </li>
          </ul>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Finding Your Data Series</h3>
          <h4 class="mt-6 text-lg font-semibold tracking-tight text-grblack sm:text-lg">Search</h4>
          <p>Now you will find the data series which you wish input into your trend. If you have an idea of the name of the data series you are looking for, you can search for specific data sets using the search bar near the top of the widget. To assist there are some filters next to the search box that further refine the data series displayed in the grid.</p>
          <br/>
          <img class="mx-auto w-full lg:w-2/3" src="@/assets/selectdataseries-image3.png" alt="Search Data Series">
          <br/>
          <h4 class="mt-6 text-lg font-semibold tracking-tight text-grblack sm:text-lg">Browse</h4>
          <p>The tabs below the search box classify the available data series into general categories. </p>
          <br/>
          <img class="mx-auto w-full lg:w-2/3" src="@/assets/selectdataseries-image4.png" alt="Browse Data Series">
          <br/>
          <h4 class="mt-6 text-lg font-semibold tracking-tight text-grblack sm:text-lg">Group</h4>
          <p>You can then use the 'Grouping By' input facets to set the tree structure of how you want your data to be grouped. As we will explain below, this is important when navigating or summating data. </p>
          <br/>
          <img class="mx-auto w-full lg:w-2/3" src="@/assets/selectdataseries-image5.png" alt="Group By Fields">
          <br/>
          <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Selecting Your Data Series</h3>
          <p>The main grid will display the data series available for you to select. The grid is organised in a tree structure. </p>
          <br/>
          <p>Following along with our example, we want to show different types of wind output data for the whole of the NEM. We have first grouped <b>by Market, <i>and then</i> by Fuel Type</b>. This has set the top level of the tree structure to be <b>Market</b> (i.e. grouping the data into the NEM or WEM) and then the second level of the tree structure to be <b>Fuel Type</b> (grouping the data series into Battery Storage, Black Coal, Brown Coal, etc.). Finally we have found the Wind fuel type and selected the specific data series we are after.</p>
          <br/>
          <img class="mx-auto w-full" src="@/assets/selectdataseries-image6.png" alt="Radio Buttons">
          <br/>
          <p>The radio buttons allow you to toggle on/off the specific data series you will input into your trend. The square radio buttons represent an individual data series while a circle radio button represents the sum of data sets within the group. </p>
          <br/>
          <p>For example, in the image below, clicking on the circular radio button (highlighted in orange) will select the sum of 'initial MW' for all generators with the fuel type of 'Wind' within the NEM.   </p>
          <br/>
          <img class="mx-auto w-full lg:w-1/3" src="@/assets/selectdataseries-image7.png" alt="Radio Buttons">
          <br/>
          <p>Once you have selected your chosen data sets, click on the blue "Next: Configure Your Data" button in the top right corner of the screen.</p>
          <br/>
          <img class="mx-auto w-full lg:w-1/5" src="@/assets/selectdataseries-image8.png" alt="Next Button">
          <br/>
          
      </div>
    </div>
  </div>
</template>

<script>
export default {
    title: 'Select Your Data Series'
};
</script>
