import { RouteConfigSingleView } from 'vue-router/types/router';
import Home from '@/views/Home.vue';
import GettingStarted from '@/views/getting-started/GettingStarted.vue';
import LoggingIn from '@/views/getting-started/LoggingIn.vue';
import Guide from '@/views/guide/Guide.vue';
import ManageTrends from '@/views/guide/1ManageTrends.vue';
import SelectDataSeries from '@/views/guide/2SelectDataSeries.vue';
import TransformData from '@/views/guide/3TransformData.vue';
import ChooseOutput from '@/views/guide/4ChooseOutput.vue';
import Format from '@/views/guide/5Format.vue';
import OutputTrend from '@/views/guide/6OutputTrend.vue';
import Glossary from '@/views/glossary/Glossary.vue';
import TimelineOfDevelopment from '@/views/ongoing-development/TimelineOfDevelopment.vue';
import ContactUs from '@/views/contact-us/ContactUs.vue';
import PageNotFound from '@/views/PageNotFound.vue';

export default [
  { path: '', name: 'Home', component: Home },
  {
    path: 'getting-started',
    name: 'Getting Started',
    component: GettingStarted,
    children: [
      { path: 'loggingin', name: 'Logging In', component: LoggingIn },
    ]
  },
  {
    path: 'guide',
    name: 'Step-by-Step Guide',
    component: Guide,
    children: [
      { path: 'managetrends', name: 'Step 1. Manage Your Trends', component: ManageTrends },
      { path: 'selectdataseries', name: 'Step 2. Select Your Data Series', component: SelectDataSeries },
      { path: 'transformdata', name: 'Step 3. Transform the Data', component: TransformData },
      { path: 'chooseoutput', name: 'Step 4. Choose Output', component: ChooseOutput },
      { path: 'format', name: 'Step 5. Formatting Your Chart', component: Format },
      { path: 'outputtrend', name: 'Step 6. Output Your Trend', component: OutputTrend },
    ]
  },
  {
    path: 'glossary',
    name: 'Glossary',
    component: Glossary,
    children: [],
  },
  {
    path: 'timeline',
    name: 'Timeline of Development',
    component: TimelineOfDevelopment,
    children: []
  },
  {
    path: 'contact-us',
    name: 'Contact Us',
    component: ContactUs,
    children: []
  },
  { path: '*', component: PageNotFound, meta: { hide: true } }
] as Array<RouteConfigSingleView>;
