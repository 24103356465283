<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/guide" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Guide</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/guide/selectdataseries" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm sm:text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                <p>Step 2: <b> Select Your Data Series</b></p>
                <!-- Heroicon name: arrow-narrow-right -->
                <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                </router-link>
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-semibold tracking-tight text-grblack sm:text-5xl sm:leading-10">Step 1: <span class="font-bold ">Manage Your Trends</span></h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">Once you've <router-link to="/getting-started/loggingin" class="inline text-grblue hover:underline">logged in</router-link> to the web app using your Global-Roam account, you should see a screen that looks something like the screenshot below. Here you will be able to create a new trend or access your existing trends.</p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <img class="mx-auto w-full lg:w-2/3" src="@/assets/managetrends-image1.png" alt="NEMreview Main Window">
            <br/>
            <br/>
            <p>Within NEMreview, we refer to a 'trend' as a single chart, table or CSV export of selected data series. NEMreview allows you configure, format and export trends for your own analysis of the energy market. The instructions on this section of the site will run you through how to create a new trend.   </p>
            <br/>
            <div class="p-4 mx-auto w-5/6 rounded-lg bg-gray-100">
                <h3 class="mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Follow Along with our Example Trend</h3>
                <p>In this step-by-step guide we will walk you through how to create a new trend. As an example of how to do this, we will be recreating the 'Monthly Wind Stats' trend that has appeared numerous times on WattClarity, namely in the article "<a href="https://wattclarity.com.au/articles/2021/05/25may-peakwindproduction/" class="inline text-grblue hover:underline" target="_blank">A new all-time maximum for coincident wind production across the NEM late on Tuesday 25th May</a>". </p>
            <br/>
            <img class="mx-auto w-full lg:w-5/6" src="@/assets/managetrends-image4.png" alt="NEMreview Main Window - Access an Existing Trend">
            <br/>
            <p>You can open your own copy of this trend at <a href="https://app.nemreview.info/#/templates/ae9ca189-40a1-4488-9c59-ac24b58708a1" class="inline text-grblue hover:underline" target="_blank">this link</a>.</p>
            </div>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Creating a New Trend</h3>
            <p>To begin click on the blue "Create a New Trend" button towards the top of the left-half of the interface. Alternatively, underneath this button there are several pre-configured example trends that are available so that you can explore their configuration.</p>
            <br/>
            <img class="mx-auto w-full lg:w-1/2" src="@/assets/managetrends-image2.png" alt="NEMreview Main Window - Create a New Trend">
            <br/>
            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Access Existing Trends</h3>
            <p>On the right-half of your screen you will be able to access trends that you (or others within your organisation) have previously created. The search bar at the top allows you to find trends by name. Clicking on the name will allow you to view and/or edit the trend.</p>
            <br/>
            <img class="mx-auto w-full lg:w-1/2" src="@/assets/managetrends-image3.png" alt="NEMreview Main Window - Access an Existing Trend">
            <br/>
        </div>
    </div>
    </div>
</template>


<script>
export default {
    title: 'Manage Your Trends'
};
</script>

<style scoped>
    .h-gif2 {
        height: 600px;
    }

    .text-grblack {
        color: #403b41;
    }
</style>