<template>
<div class="relative py-16 bg-white overflow-hidden">
  <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
  </div>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <div class="text-lg mx-auto mb-6">
      <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Step-by-Step Guide</h1> 
      <p class="text-xl text-gray-500 leading-8">This step-by-step has been written to walk you through how to create a new trend in NEMreview v7 from start to finish. The sections below contain instructions for each step of this process.</p>
      <br/>
      <p class="text-xl text-gray-500 leading-8">While NEMreview v7 remains in beta development, this guide has been prepared for users to get the most out of the app in it's current state. The concepts outlined in the guide are also detailed so that users can see where the product is heading, but we stress that <b>the current implementation is likely to continue to evolve</b> as we work to improve the user experience and capabilities of the app. We encourage you to help us during this process by <router-link to="/contact-us" class="inline text-grblue hover:underline">providing any feedback</router-link> you have as you use the app.</p>
    </div>
    <br/>
    <ul class="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">
      <router-link to="/guide/managetrends" class="block">
      <li class="col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-8 font-bold hover-text-grblue lg:text-lg">1. Manage Your Trends</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Create a new trend or access an existing trend. </p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/guide/selectdataseries" class="block">
      <li class="col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-8 font-bold hover-text-grblue lg:text-lg">2. Select Your Data Series</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">What data is available and how to input it.</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/guide/transformdata" class="block">
      <li class="col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-8 font-bold hover-text-grblue lg:text-lg">3. Transform Your Data </h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Configuring data series and adding calculations.</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/guide/chooseoutput" class="block">
      <li class="col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-8 font-bold hover-text-grblue lg:text-lg">4. Choose Output</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Output your trend as a chart, table or CSV file.</p>
          </div>
        </div>
      </li>
      </router-link>

      <router-link to="/guide/format" class="block">
      <li class="col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-8 font-bold hover-text-grblue lg:text-lg">5. Formatting Your Chart</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">How to format and edit your chart.</p>
          </div>
        </div>
      </li>
      </router-link>

    <router-link to="/guide/outputtrend" class="block">
    <li class="col-span-1 bg-white rounded-lg shadow hover:bg-gray-100">
        <div class="w-full flex items-center justify-between p-6 space-x-6">
          <div class="flex-1">
            <div class="flex items-center space-x-3">
              <h3 class="text-grblack xl:text-xl leading-8 font-bold hover-text-grblue lg:text-lg">6. Output Your Trend</h3>
            </div>
            <p class="mt-1 text-gray-500 text-sm leading-5">Share, save and export your trend.</p>
          </div>
        </div>
      </li>
      </router-link>

    </ul>

  </div>
</div>

</template>


<script>
export default {
    title: 'Step-by-Step Guide'
};
</script>