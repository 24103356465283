<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mx-auto mb-6 pb-6 border-b-1">
            <h1 class="mt-4 mb-10 text-4xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">Glossary</h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">This page contains explanations of general principles and a glossary of data series available within NEMreview v7. Please note that the information provided here is in no way complete or comprehensive. We will look to progressively add to this page over time to clarify any data where we feel that further explanation is necessary. </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>Seeking further clarification? Please note:  </p>
            <ul class="pl-16 pt-2 pb-2 list-disc">
                <li>We have established, and will continue to evolve, a <a href="https://wattclarity.com.au/other-resources/explanations/" class="inline text-grblue hover:underline" target="_blank">general glossary of NEM-specific terms</a> over on WattClarity®.</li>
                <li>If you have suggestions for this page or need clarification on a data series within NEMreview, then please <router-link to="/contact-us" class="inline text-grblue hover:underline">contact us</router-link> directly.</li>
            </ul>
            <br/>
            <h2 class="pt-4 pb-2 text-3xl font-bold text-grblack sm:text-2xl">General Principles</h2>
            <p>As a starting point, we've provided a written explination of some of the general principles in the NEM that we’re commonly asked about:</p>
            <div class="w-full md:w-5/6 mx-auto p-8 rounded-md">
                <div class="shadow-md rounded-md">
                    <div class="tab w-full overflow-hidden border-t">
                    <input class="absolute opacity-0" id="tab-multi-one" type="checkbox" name="tabs">
                    <label class="block p-5 leading-normal cursor-pointer font-semibold" for="tab-multi-one">Supply & Demand</label>
                    <div class="tab-content overflow-hidden border-l-2 bg-gray-50 border-indigo-500 leading-normal">
                        <div class="p-5">
                            <p>Your licence to NEMreview provides you access to several different measurements of electricity supply (injections into the grid) and electricity demand (withdrawals from the grid). </p>
                            <br/>
                            <p>We’ll start by explaining some of the fundamental concepts you’ll need to be familiar with to make sense of all of the different ways in which AEMO and others speak about both electricity supply, and demand.</p>
                            <br/>
                            <p>Back in 2018, we wrote a lengthy WattClarity article that covers how demand, and hence dispatch works in the NEM:</p>
                            <p><a href="https://wattclarity.com.au/articles/2018/04/an-explainer-about-electricity-demand-take-1/" class="inline text-grblue hover:underline font-bold" target="_blank">An Explainer about Electricity Demand</a></p>
                            <br/>
                            <p>The article provides anintroduction to these principles, with the key points being that:</p>
                            <ol class="pl-16 pt-2 pb-2 list-decimal">
                                <li>All data in NEMreview is data <b>pertaining to <i>dispatch</i></b> (as data pertaining to settlement is confidential);</li>
                                <li>We measure demand by <b>adding up injections on the supply side</b> of the grid.</li>
                                <li>There are <b><i>different</i> measures</b> of demand – none are perfect, and they are all used for different purposes – so ensure you are comparing apples to apples.</li>
                            </ol>
                            <p>When thinking about supply (or demand) it’s helpful to keep the following four dimensions in mind:</p>
                            <br/>
                            <p class="pl-4"><b><u>1st Dimension</u></b> is about <b>what you add up</b> to get to a number (e.g. this is where the difference between ‘Underlying Demand’ and ‘Operational Demand’ plays out).  </p>
                            <br/>
                            <p class="pl-4"><b><u>2nd Dimension</u></b> is about <b>what you subtract off</b> to get to a number (e.g. this is where the concept of ‘sent out’ plays out).</p>
                            <br/>
                            <p class="pl-4"><b><u>3rd Dimension</u></b> is about the time span you use to get to a number (e.g. sometimes data is for a 5-minute period and sometimes for a 30-minute period).</p>
                            <br/>
                            <p class="pl-4"><b><u>4th Dimension</u></b> is about your <b>point of reference in time</b> of the measurement point (are you looking forward, or are you looking backwards?)</p>
                            <br/>
                            <p>With these general principles in mind, you can find more about the specific measures:</p>
                            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Demand</h3>
                            <p>Demand figures published by AEMO and accessible in NEMreview are aggregate measures across a large number of energy users (typically for a region). There are three demand terms you’ll generally hear in NEM conversations:</p>
                            <ul class="pl-16 pt-2 pb-2 list-disc">
                                <li>Underlying Demand (or Native Demand)</li>
                                <li>Operational Demand (or Grid Demand)</li>
                                <li>Scheduled Demand (or Market Demand)</li>
                            </ul>
                            <p>These three terms are explained in detail in the WattClarity article we have linked to above.</p>
                            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Supply</h3>
                            <p>Supply figures published by AEMO and accessible in NEMreview are discrete measures, and typically applicable for an individual DUID (dispatchable unit).</p>
                            <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Negawatts</h3>
                            <p>The <a href="https://wattclarity.com.au/other-resources/explanations/glossary/wdrm/" class="inline text-grblue hover:underline" target="_blank">Wholesale Demand Response Mechanism (WDRM)</a> commences operations on Sunday 24th October 2021.</p>
                            <br/>
                            <p>It’s important to note that, despite negawatts being delivered by energy users, they are treated as supply-side commodities in NEMDE.</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <br/>
            <h2 class="pt-4 pb-2 text-3xl font-bold text-grblack sm:text-2xl">Glossary by Data Source</h2>
            <p>Below is an <i>incomplete</i> list of data series available within NEMreview v7. The tables below are grouped by original source and a short description is provided for each series.  We will look to progressively add to and expand this list as time allows. We recommend that you first read through the 'General Principles' section above to gain a greater context of the series listed below. </p>
            <br/>
            <div class="p-4 w-1/3 rounded-md bg-gray-100 border-2 overflow-hidden">
                <h3 class="mb-2 text-xl font-semibold tracking-tight text-grblack sm:text-xl">Sections</h3>
                <ol class="pl-16 pt-2 pb-2 list-decimal">
                    <li><a href="#aemonem" class="inline text-grblue hover:underline">AEMO (NEM)</a></li>
                    <li><a href="#aemowem" class="inline text-grblue hover:underline">AEMO (WEM)</a></li>
                    <li><a href="#asxenergy" class="inline text-grblue hover:underline">ASX Energy</a></li>
                    <li><a href="#bom" class="inline text-grblue hover:underline">BOM</a></li>
                    <li><a href="#rec" class="inline text-grblue hover:underline">REC Registry</a></li>
                    <li><a href="#greenenergy" class="inline text-grblue hover:underline">Green Energy Markets</a></li>
                    <li><a href="#apvi" class="inline text-grblue hover:underline">APVI</a></li>
                    <li><a href="#derived" class="inline text-grblue hover:underline">Derived</a></li>
                </ol>
            </div>
            <br/>
            <br/>
            <div class="flex flex-col" id="aemonem">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-grblack">
                        <tr>
                        <th class="w-3/12 px-6 py-3 text-left text-xl font-bold text-white tracking-wider">
                            AEMO (NEM)
                        </th>
                        <th class="w-9/12"></th>
                        </tr>
                    </thead>
                    <thead class="bg-gray-200">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Data Series Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Description
                        </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Price (Dispatch)
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The 5-minute dispatch price for the given region.  </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Price (Trading)
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The trading price for the given region. Pre 5-minute Settlement, the trading price was set every 30 minutes and was the average of the six dispatch prices contained within the 30 minute period. Post 5-minute Settlement, this equates to the 5-minute dispatch price. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Operational Demand
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>Refer to 'Supply and Demand' explainer in the General Principles section above.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Demand and Non-Scheduled Generation
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>Refer to 'Supply and Demand' explainer in the General Principles section above.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Total Demand
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>Refer to 'Supply and Demand' explainer in the General Principles section above. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Initial MW
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>A snapshot of generation of a unit at the start of a dispatch interval. Refer to 'Supply and Demand' explainer in the General Principles section above. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Target
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>A snapshot of generation of a unit at the end of a dispatch interval (the output targetted). Refer to 'Supply and Demand' explainer in the General Principles section above. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Dispatch Availability
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>For semi-scheduled units, this is the last 5-minute ahead forecast – either from AEMO’s AWEFS or ASEFS systems, or a generator-provided self-forecast. For scheduled units, this is what they’ve bid as MAXAVAIL.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Max Capacity
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The maximum MW that the generator is able to bid (and therefore output). Provided by the generator to the AEMO on registration but occasionally updated if units are upgraded/downgraded. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Registered Capacity
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The 'nameplate' MW capacity that the plant is rated at. Registered Capcity is used by the AEMO in their calculations for wind and solar forecasts. Provided by the generator to the AEMO on registration but occasionally updated if units are upgraded/downgraded. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Cumulative Price
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>A rolling total of the previous 7 days worth of trading prices for the given region. Prior to 5-Minute Settlement this means the sum of the most recent 336 (30-minute) trading prices for the region. Post 5-Minute Settlement this means the sum of the most recent 2,016 (5-minute) trading prices for the region. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            UIGF
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>Unconstrained Intermittent Generation Forecast. The last 5-min-ahead forecast of generation of semi-scheduled units.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Wind UIGF
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The last 5-min-ahead forecast of generation of semi-scheduled wind units.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Solar UIGF
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The last 5-min-ahead forecast of generation of semi-scheduled solar units.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Semi-Scheduled Cleared MW
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The MW amount actually dispatched for semi-scheduled units. i.e. after constraints and economic curtailment.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Total Intermittent Generation
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The MW amount dispatched for non-scheduled wind and solar units. Non-scheduled units are <i>generally</i> older or smaller units.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Solar Cleared MW 
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The total of the dispatch targets for semi-scheduled solar units for the interval.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Wind Cleared MW 
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The total of the dispatch targets for semi-scheduled wind units for the interval.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Regions Trading Dispatchable Generation
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The sum for the region of the dispatch targets for scheduled and semi-scheduled generators for the interval i.e. the generation that was ultimately dispatched, not what was available to be dispatched. </p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <div class="flex flex-col" id="aemowem">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-grblack">
                        <tr>
                        <th class="w-3/12 px-6 py-3 text-left text-xl font-bold text-white tracking-wider">
                            AEMO (WEM)
                        </th>
                        <th class="w-9/12"></th>
                        </tr>
                    </thead>
                    <thead class="bg-gray-200">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Data Series Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Description
                        </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Price
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The Balancing Market Price for the region. </p>
                            <br/>
                            <p>The WEM is not an ‘energy only’ market (like the NEM) – it also has a capacity market.  Capacity market prices are not currently provided in NEMreview v7.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Actual Total Generation
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>Actual Total Generation is the total MW output of all generation produced within the region.  </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            End of Interval Quantity
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The total MW output of energy generated by a given generator, measured at the end of the interval.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Energy Generated
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The MW of energy generated during the given dispatch interval for a unit. </p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <div class="flex flex-col" id="asxenergy">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-grblack">
                        <tr>
                        <th class="w-3/12 px-6 py-3 text-left text-xl font-bold text-white tracking-wider">
                            ASX Energy
                        </th>
                        <th class="w-9/12"></th>
                        </tr>
                    </thead>
                    <thead class="bg-gray-200">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Data Series Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Description
                        </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Open Interest
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The total number of options and/or futures contracts that are not closed or delivered as at close of business on the previous day. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Price
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>Daily closing price for the given futures contract. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Volume
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>Daily trading volume for the given futures contract.</p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <div class="flex flex-col" id="bom">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-grblack">
                        <tr>
                        <th class="w-3/12 px-6 py-3 text-left text-xl font-bold text-white tracking-wider">
                            BOM
                        </th>
                        <th class="w-9/12"></th>
                        </tr>
                    </thead>
                    <thead class="bg-gray-200">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Data Series Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Description
                        </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Apparent Temperature
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>Apparent Temperature is a measure of temperature that takes into account the combined effects of air temperature, relative humidity and wind speed for a given location. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            MSL Pressure
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>MSL (Mean Sea-Level) Pressure is a measure of the average atmospheric pressure at sea-level for a given location.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            QNH Pressure
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>QNH Pressure is used by pilots to set the altimeter of their aircraft. QNH pressure is closely related to Mean Sea Level Pressure (MSL Pressure). It differs slightly to MSL Pressure at low elevations, and can vary significantly from MSL Pressure at high elevations.</p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Wind Speed
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The wind observation averaged over 10 minutes in km/h for a given location. Measured at 10m elevation. </p>
                        </td>
                        </tr>
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Gust
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The wind observation measured over 3 seconds in km/h for a given location. Measured at 10m elevation. This is published every 10 minutes, and will differ slightly to wind speed as it is originally measured in smaller increments. </p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <div class="flex flex-col" id="rec">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-grblack">
                        <tr>
                        <th class="w-3/12 px-6 py-3 text-left text-xl font-bold text-white tracking-wider">
                            REC Registry
                        </th>
                        <th class="w-9/12"></th>
                        </tr>
                    </thead>
                    <thead class="bg-gray-200">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Data Series Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Description
                        </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Large-Scale Generation Certificates
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The number of Large-Scale Generation Certificates created by a given generator. </p>
                            <br/>
                            <p>Published by the Clean Energy Regulator through the REC Registry once per year. More information available on the <a href="https://www.rec-registry.gov.au/rec-registry/app/public/lgc-register" class="inline text-grblue hover:underline" target="_blank">REC Registry website</a>.</p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <div class="flex flex-col" id="greenenergy">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-grblack">
                        <tr>
                        <th class="w-3/12 px-6 py-3 text-left text-xl font-bold text-white tracking-wider">
                            Green Energy Markets
                        </th>
                        <th class="w-9/12"></th>
                        </tr>
                    </thead>
                    <thead class="bg-gray-200">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Data Series Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Description
                        </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            LGC
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>LGC Spot Price. Large-scale Generation Certificates (LGCs) are traded through a wholesale market with minimum parcel sizes of 5,000 certificates. A spot price is calculated which represents the market value of these parcels.</p>
                            <br/>
                            <p>Published by Green Energy Markets on a daily basis with previous day's data accessible from next business day. More information available on the <a href="http://greenmarkets.com.au/resources/lgc-market-prices" class="inline text-grblue hover:underline" target="_blank">Green Energy Market website</a>.</p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <div class="flex flex-col" id="apvi">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-grblack">
                        <tr>
                        <th class="w-3/12 px-6 py-3 text-left text-xl font-bold text-white tracking-wider">
                            APVI
                        </th>
                        <th class="w-9/12"></th>
                        </tr>
                    </thead>
                    <thead class="bg-gray-200">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Data Series Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Description
                        </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Small Solar Estimate 
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>An estimate of total rooftop solar generation based on more than 6000 PV systems registered with the APVI.</p>
                            <br/>
                            <p>Published by the Australian PV Institute at an up-to 5 minute basis. More information available on the <a href="https://pv-map.apvi.org.au/live" class="inline text-grblue hover:underline" target="_blank">APVI website</a>.</p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <div class="flex flex-col" id="derived">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-grblack">
                        <tr>
                        <th class="w-2/12 px-6 py-3 text-left text-xl font-bold text-white tracking-wider">
                            Derived
                        </th>
                        <th class="w-2/12"></th>
                        <th class="w-8/12"></th>
                        </tr>
                    </thead>
                    <thead class="bg-gray-200">
                        <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Data Series Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Formula
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-grblack uppercase tracking-wider">
                            Description
                        </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm font-bold text-gray-900">
                            Energy Generated
                            </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-900"><i>Energy Generated</i> = (Initial MW + next Initial MW)/24 </div>
                        </td>
                        <td class="px-6 py-4 whitespace-wrap text-sm leading-5 text-gray-900">
                            <p>The MWh of energy generated during the given dispatch interval, estimated by averaging the generation in MW at the start of the interval (InitialMW) with the generation at the end of the interval (InitialMW for the following interval), and then dividing this average MW figure by 12 to get MWh generated in that 5 minute period.</p>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    </div>
</template>


<style scoped>
    /* Tab content - closed */
    .tab-content {
    max-height: 0;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
    }
    /* :checked - resize to full height */
    .tab input:checked ~ .tab-content {
    max-height: 200vh;
    }
    /* Label formatting when open */
    .tab input:checked + label{
    /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
    font-size: 1.25rem; /*.text-xl*/
    padding: 1.25rem; /*.p-5*/
    border-left-width: 2px; /*.border-l-2*/
    border-color: #5273aa; /*.border-indigo*/
    background-color: #d1d5db; /*.bg-gray-300 */
    color: #5273aa; /*.text-indigo*/
    }
    /* Icon */
    .tab label::after {
    float:right;
    right: 0;
    top: 0;
    display: block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5;
    font-size: 1.25rem;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
    }
    /* Icon formatting - closed */
    .tab input[type=checkbox] + label::after {
    content: "+";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
    }
    .tab input[type=radio] + label::after {
    content: "\25BE";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
    }
    /* Icon formatting - open */
    .tab input[type=checkbox]:checked + label::after {
    transform: rotate(315deg);
    background-color: #5273aa; 
    color: #f8fafc; /*.text-grey-lightest*/
    }
    .tab input[type=radio]:checked + label::after {
    transform: rotateX(180deg);
    background-color: #5273aa; 
    color: #f8fafc; /*.text-grey-lightest*/
    }
</style>

<script>
export default {
    title: 'Glossary'
};

 /* Javascipt for accordian */
/* var myRadios = document.getElementsByName('tabs2');
var setCheck;
var x = 0;
for(x = 0; x < myRadios.length; x++){
    myRadios[x].onclick = function(){
        if(setCheck != this){
            setCheck = this;
        }else{
            this.checked = false;
            setCheck = null;
    }
    };
} */

</script>