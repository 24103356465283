<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg mb-2 mx-auto">
            <router-link to="/guide" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Guide</p></router-link>
        </div>
        <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <router-link to="/guide/transformdata" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    <p>Step 3: <b> Transform the Data</b></p>
                </router-link>
            </div>
            <div class="hidden md:-mt-px md:flex">
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <router-link to="/guide/format" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <p>Step 5: <b> Formatting Your Chart</b></p>
                    <!-- Heroicon name: arrow-narrow-right -->
                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </router-link>
            </div>
        </nav>
        <div class="text-lg mx-auto mb-6">
            <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-semibold tracking-tight text-grblack sm:text-5xl sm:leading-10">Step 4: <span class="font-bold ">Choose Output</span></h1>
            <p class="text-xl text-gray-500 leading-8 font-semibold">At this step you will choose from one of three available methods of how you would like to view or access the data in your trend. </p>
        </div>
        <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
            <p>Simply click on one of the output options as shown below. Once your trend has saved, you will be able to revisit this page at any time if you wish to output your data in a different method. </p>
            <br/>
            <br/>
            <img class="mx-auto w-full lg:w-2/3" src="@/assets/chooseoutput-image1.png" alt="Choosing your ouput">
            <br/>
            <p>If you are following along with our example, you will click on 'Chart'. In the next step we will show you <router-link to="/guide/format" class="inline text-grblue hover:underline">how to format the chart</router-link>.</p>
            <br/>
            <p>Please <router-link to="/contact-us" class="inline text-grblue hover:underline">contact us</router-link> if you are interested in an API to access the data you need, or if there are other ways in which you would like to output your data.</p>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: 'Choose Output'
};
</script>