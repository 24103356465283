<template>
    <div id="app" class="bg-image">
        <header-section />
        <div class="flex overflow-hidden w-3/4 mx-auto">
            <sidebar-menu />
            <content-area>
                <router-view />
            </content-area>
        </div>
        <footer-section />
    </div>
</template>

<script>
import HeaderSection from '@/components/Header.vue';
import SidebarMenu from '@/components/SidebarMenu.vue';
import ContentArea from '@/components/ContentArea.vue';
import FooterSection from '@/components/Footer.vue';

export default {
    name: 'App',
    components: {
        HeaderSection,
        SidebarMenu,
        ContentArea,
        FooterSection
    }
};
</script>

<style>
body {
    margin: 0;
    padding: 0;
}

.prose .max-w-prose .prose-lg {
    max-width: 100ch;
}

#app {
    padding: 0;
    margin: 0;
}

.text-grblack {
    color:  #403b41;
}

li:hover .hover-text-grblue {
    color:  #5273aa;
}

a:hover .hover-link-grblue {
    color:  #5273aa;
}

.text-grblue {
    color:  #5273aa;
}

.text-grred {
    color:  #E53E45;
}

.text-grgreen {
    color:  #7DAD13;
}

.text-gryellow {
    color:  #DDB400;
}

.textheader-grblue:hover {
    color:  #5273aa;
}

.textheader-grred:hover {
    color:  #E53E45;
}

.hover-text-grred:hover {
    color:  #E53E45;
}

.textheader-grgreen:hover {
    color:  #7DAD13;
}

.textheader-gryellow:hover {
    color:  #DDB400;
}

.bg-grblack {
    background-color: #403b41;
}

.bg-grblack-300 {
  background-color: rgba(64, 59, 65, 0.4);
}

.bg-grgreen {
    background-color: #7DAD13;
}

.bg-grgray {
    background-color: #f0f0f0;
}

.hover-bg-grblue:hover {
    background-color:  #5273aa;
}

.border-grblack {
    --border-opacity: 1;
    border-color: #403b41;
}

.bg-image {
    background-image: url("assets/ez2view-background.png");
    margin: 0;
    padding: 0;
}

.hover-hide {
    display: none; 
}

li:hover .hover-show{
    display: none; 
}

li:hover .hover-hide{
     display: inline;
}

</style>
