<template>
    <div class="relative py-16 bg-white overflow-hidden">
        <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        </div>
        <div class="relative px-4 sm:px-6 lg:px-8">
            <div class="text-lg mb-2 mx-auto">
                <router-link to="/guide" class="block"><p class="text-base text-center leading-4 text-grblue font-semibold tracking-wide hover:underline">Guide</p></router-link>
            </div>
            <nav class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
                <div class="-mt-px w-0 flex-1 flex">
                    <router-link to="/guide/selectdataseries" class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                        <!-- Heroicon name: arrow-narrow-left -->
                        <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                        <p>Step 2: <b> Select Data Series</b></p>
                    </router-link>
                </div>
                <div class="hidden md:-mt-px md:flex">
                </div>
                <div class="-mt-px w-0 flex-1 flex justify-end">
                    <router-link to="/guide/chooseoutput" class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-md font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                        <p>Step 4: <b> Choose Output</b></p>
                        <!-- Heroicon name: arrow-narrow-right -->
                        <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </router-link>
                </div>
            </nav> 
            <div class="text-lg mx-auto mb-6">
                <h1 class="mt-6 mb-10 text-4xl text-center leading-10 font-semibold tracking-tight text-grblack sm:text-5xl sm:leading-10">Step 3: <span class="font-bold ">Transform the Data</span></h1>
                <p class="text-xl text-gray-500 leading-8 font-semibold">At this step you will be given several options for configuring and customising the data series that you have selected, as well as add in your own calculated series. </p>
            </div>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Configuring Your Data Series</h3>
                <p>You should see all of the data series that you selected in the last step now in a list format. You can start by giving a name/label to each series within your trend. You can leave this blank if you wish to display the default name for the data series. </p>
                <br/>
                <img class="mx-auto w-full" src="@/assets/transformdata-image7.png" alt="List of Data Series">
                <br/>
                <p>After you have done this, you can also specify: </p>
                <ul class="pl-16 pt-2 pb-2 list-disc">
                    <li><b>Time Step</b> - The spacing of each data point for the series. The default selection "Raw" displays the data in the same time step at which it is originally published.</li>
                    <li><b>Aggregate</b> - The data output for the time step - i.e. average, min, max, sum/total or count. </li>
                    <li><b>Display</b> - A toggle for whether you want the series displayed. You may wish to include a series within a calculation but not on the final output of your analysis, in those cases you would toggle off the display option.</li>
                </ul>
                <br/>
                <img class="mx-auto w-full lg:w-1/2" src="@/assets/transformdata-image1.png" alt="Add a Calculated Series">
                <br/>
                <p>You can also duplicate or delete series using the buttons shown below. Duplication is useful if you want to display different readings of the same data series (e.g. max and min for a specific series). </p>
                <br/>
                <img class="mx-auto w-full lg:w-1/3" src="@/assets/transformdata-image2.png" alt="Add a Calculated Series">
                <br/>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Adding a Calculated Data Series</h3>
                <p>You can add your own calculated series incorporating series that selected in the last step. To do this, first click on the 'Add Calculated Series' button that displays below the list of series. </p>
                <br/>
                <p>Next, you should give the series a unique name, and then in the 'Input' column, you should add in the formula of your new calculated series using the ID letters. You'll notice that each series is given an ID letter. Use these IDs to call a data series in your formula of your calculated series. </p>
                <br/>
                <br/>
                <img class="mx-auto w-full lg:w-1/6" src="@/assets/transformdata-image8.png" alt="Add a Calculated Series">
                <br/>
                <p>The supported operators for calculated series are:</p>
                <ul class="pl-16 pt-2 pb-2">
                    <li><b>+</b> Addition</li>
                    <li><b>-</b> Subtraction</li>
                    <li><b>/</b> Division</li>
                    <li><b>*</b> Multiplication</li>
                    <li><b>^</b> Power</li>
                    <li><b>%</b> Modulo</li>
                    <li><b>()</b> Parentheses</li>
                    <li><b>MAX (a, n)</b> Maximum (where a is the row reference and n is the number or cell reference)</li>
                    <li><b>MAX (a, n)</b> Minimum (where a is the row reference and n is the number or cell reference)</li>
                </ul>
                <br/>
                <p>Continuing with our example, we have duplicated the 'NEM Wind Initial MW' series twice in order for us to display the avg, max and min on the chart we are trying to create. </p>
                <p>You can also see in the screenshot below that we have now added in two of our own calculated series "Min/Installed Percent" and "Capacity Factor Percent" respectively. Looking at the "Capacity Factor Percent" series that we have added in last - you can see that we have written the formula "<b>A/C</b>" - using the / operator to divide Series A by Series C. </p>
                <br/>
                <img class="mx-auto w-full" src="@/assets/transformdata-image4.png" alt="Add a Calculated Series">
                <br/>
                <p><b>Please note:</b></p>
                <ul class="pl-16 pt-2 pb-2 list-disc">
                    <li><b>Creating calculated series using inputs of different time steps</b>. If your calculated series has inputs that contain different time steps, then the calculated value in the output will be for when the time stamps coincide. For example, say you configure a calculated series that multiplies “Energy Generated” at a 5 min time step against “Regional Price” at a 30 min time step - each result in the calculated series will be the Regional Price value at the end of the 30 minute period multiplied by the <u>last</u> 5 min Energy Generated value that falls at the end of the same 30 minute period (i.e. not the sum of all 5 min values that fall within the 30 minutes). If you do not intend to conduct your analysis like this, we recommend that you use series with the same cadences in your calculations and aggregate those with smaller cadences where necessary.</li>
                    <li>You can create nested calculated series (a calculated series that references another calculated series). Simply use the Series ID of the calculated series in the formula. </li>
                </ul>
                <h3 class="mt-10 mb-2 text-2xl font-semibold tracking-tight text-grblack sm:text-2xl">Ordering Your Series</h3>
                <p>The order of your series determines the sequence of how your data will be layered in the display, which is especially important if you are exporting the trend as a chart. The bottom series will be layered at the front of your chart, and top series will be layered at the back of your chart. You can change the order of your series by clicking and dragging the hamburger icon as shown below.</p>
                <br/>
                <img class="mx-auto w-full lg:w-1/3" src="@/assets/transformdata-image5.png" alt="Reordering Series">
                <br/>
                <p>In our example, we want the 'Capacity Factor Percent' series shown at the front of our chart, so we have dragged this to the top and then ordered the remaining series in our desired sequence like shown below. </p>
                <br/>
                <img class="mx-auto w-full" src="@/assets/transformdata-image6.png" alt="Final Order">
                <br/>
                <p>Once you have configured the data series as desired, click on the blue "Next: View Your Outputs" button in the top right corner of the screen.</p>
                <br/>
                <img class="mx-auto w-full lg:w-1/6" src="@/assets/transformdata-image9.png" alt="Next Button">
                <br/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    title: 'Transform the Data'
};
</script>
