<template>
    <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="mx-auto mb-6">
            <h3 class="mt-2 mb-2 text-2xl text-center font-semibold tracking-tight text-grblack lg:text-4xl">Sorry! Page Not Found</h3>
            <p class="mt-2 mb-10 text-6xl text-center leading-12 font-extrabold tracking-tight text-grred">404 Error</p>
            <div class="text-gray-500 mx-auto leading-7 font-normal text-lg">
                <br/>
                <img class="w-3/5 rounded-m mx-auto" src="@/assets/pagenotfound-image1.png" alt="Page Not Found">
                <br/>
                <div class="mx-auto text-center">
                    <router-link to="/" class="block">
                    <button type="button" class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-sm text-white bg-grblack hover-bg-grblue focus:outline-none focus:ring-2 focus:ring-offset-2">
                    <!-- Heroicon name: solid/mail -->
                    <svg class="text-gray-300 mr-4 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                    </svg>
                    Back to Home
                    </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    title: '404 Error'
};
</script>