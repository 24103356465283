<template>
    <div class="bg-white">
        <div class="max-w-screen-xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
            <div class="text-center">
                <h1 class="mt-1 mb-6 text-3xl text-center leading-10 font-extrabold tracking-tight text-grblack sm:text-5xl sm:leading-10">NEMreview v7 User Portal</h1>
                <p class="mt-2 pb-2 mx-auto text-lg leading-7 text-gray-500">This site is designed to assist <b>existing users</b> who already have a licence to NEMreview. The information provided here relates to <u>version 7 of NEMreview</u>, a web-based app which is <b>currently in beta development</b>. </p>
                <br/>
                <p class="mt-2 pb-2 mx-auto text-lg leading-7 text-gray-500">Further information about the preceding version, can be found on the <a href="https://v6.nemreview.info/" class="inline text-grblue hover:underline">NEMreview v6 Help Site</a>. If you do not currently have a licence to NEMreview and want to find out more, please visit <a href="https://www.nemreview.info/" class="inline text-grblue hover:underline">our general product website</a> to learn more.</p>
                <div class="relative pb-2 px-4 sm:px-6 lg:pb-2 lg:px-8">
                    <div class="absolute inset-0">
                        <div class="bg-white h-1/3 sm:h-2/3"></div>
                    </div>
                    <div class="relative max-w-3xl mx-auto">
                        <div class="mt-8 grid gap-5 max-w-lg mx-auto lg:grid-cols-2 lg:max-w-none">
                            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div class="flex-shrink-0">
                                    <img class="h-44 w-full object-cover" src="@/assets/home-image1.jpg" alt="">
                                </div>
                                <router-link to="/getting-started" class="block h-full">
                                <div class="h-full flex-1 bg-white p-4 flex flex-col justify-between hover:bg-gray-100">
                                    <div class="flex-1">
                                        <p class="text-2xl leading-6 font-bold text-grblue hover:underline">
                                            Getting Started
                                        </p>
                                        <h3 class="mt-2 text-lg leading-6 text-gray-900">
                                            Basic information about NEMreview v7 and logging in.
                                        </h3>
                                    </div>
                                </div>
                                </router-link>
                            </div>
                            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div class="flex-shrink-0">
                                    <img class="h-44 w-full object-cover" src="@/assets/home-image2.png" alt="">
                                </div>
                                <router-link to="/guide" class="block h-full">
                                <div class="h-full flex-1 bg-white p-4 flex flex-col justify-between hover:bg-gray-100">
                                    <div class="flex-1">
                                        <p class="text-2xl leading-6 font-bold text-grblue hover:underline">
                                            Step-by-Step Guide
                                        </p>
                                        <h3 class="mt-2 text-lg leading-6 text-gray-900">
                                            Instructions for creating and managing trends.
                                        </h3>
                                    </div>
                                </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative pb-4 px-4 sm:px-6 lg:pb-6 lg:px-8">
                    <div class="absolute inset-0">
                        <div class="bg-white h-1/3 sm:h-2/3"></div>
                    </div>
                    <div class="relative max-w-3xl mx-auto">
                        <div class="mt-6 grid gap-5 max-w-lg mx-auto lg:grid-cols-2 lg:max-w-none">
                            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div class="flex-shrink-0">
                                    <!-- <img class="h-44 w-full object-cover" src="https://www.stationfive.com/wp-content/uploads/2020/01/software-development.jpg" alt=""> -->
                                    <img class="h-44 w-full object-cover" src="@/assets/home-image3.jpg" alt="">
                                </div>
                                <router-link to="/timeline" class="block h-full">
                                <div class="h-full flex-1 bg-white p-4 flex flex-col justify-between hover:bg-gray-100">
                                    <div class="flex-1">
                                        <p class="text-2xl leading-6 font-bold text-grblue hover:underline">
                                            Timeline of Development
                                        </p>
                                        <h3 class="mt-2 text-lg leading-6 text-gray-900">
                                            Details of enhancements and updates from our development team.
                                        </h3>
                                    </div>
                                </div>
                                </router-link>
                            </div>
                            <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div class="flex-shrink-0">
                                    <img class="h-44 w-full object-cover" src="@/assets/home-image4.jpg" alt="">
                                </div>
                                <router-link to="/contact-us" class="block h-full">
                                <div class="h-full flex-1 bg-white p-4 flex flex-col justify-between hover:bg-gray-100">
                                    <div class="flex-1">
                                        <p class="text-2xl leading-6 font-bold text-grblue hover:underline">
                                            Contact Us
                                        </p>
                                        <h3 class="mt-2 text-lg leading-6 text-gray-900">
                                            Get in touch with our team if you need individual support.
                                        </h3>
                                    </div>
                                </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    title: 'Home'
};
</script>

<style scoped>
.bg-grblack {
    background-color: #403b41;
}

</style>
